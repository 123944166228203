import {
  ImageChangeOptions,
  Nullable,
  StoreState,
  Thunk,
  ThunkExtra,
  ThunkWithArgs,
} from '../../types';
import { myAccountAppDefinitionId } from '../../constants/app-definition-id';
import { myAccountPageId } from '../../constants/section-id';
import {
  getSetEditCoverAction,
  getSetEditNameAction,
  getSetEditPictureAction,
  getSetEditTitleAction,
  getStopEditingProfileAction,
  getToggleIsCoverLoadingAction,
  getToggleIsCoverRepositionModeAction,
} from '../actions';

import { Applications } from '../../services/public-api-store';
import { MY_ACCOUNT_PAGE_WIDGET_ID } from '../../constants/widgets-ids';

export const enterCoverRepositionMode = () => {
  return getToggleIsCoverRepositionModeAction();
};

const navigateToMyAccount = async (
  { users, site }: StoreState,
  { getPublicAPI }: ThunkExtra,
) => {
  const membersAreaAPI = await getPublicAPI(Applications.MembersArea);
  const { uid, slug } = users.viewed;

  return membersAreaAPI?.navigateToSection({
    appDefinitionId: myAccountAppDefinitionId,
    sectionId: myAccountPageId,
    memberId: slug || uid,
    widgetId: MY_ACCOUNT_PAGE_WIDGET_ID,
  });
};

export const toggleIsEditingProfile: Thunk =
  () => (dispatch, getState, extra) =>
    navigateToMyAccount(getState(), extra);

export const stopEditingProfile: Thunk = () => (dispatch, getState) => {
  const { users } = getState();
  const { viewed } = users;

  dispatch(getStopEditingProfileAction(viewed.name, viewed.title ?? null));
};

export const setEditName = (editName: string) => getSetEditNameAction(editName);

export const setEditTitle = (editTitle: string) =>
  getSetEditTitleAction(editTitle);

export const setEditPicture = (options: ImageChangeOptions) =>
  getSetEditPictureAction(options);

export const setEditCover: ThunkWithArgs<Nullable<ImageChangeOptions>> =
  (options) => (dispatch) => {
    dispatch(getToggleIsCoverLoadingAction());
    dispatch(getSetEditCoverAction(options));
    dispatch(getToggleIsCoverLoadingAction());
  };
